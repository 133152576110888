import { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { Link, useLocation } from "react-router-dom";

export const TableComponent = ({ data = [], search = false }) => {
    const loaction = useLocation();
    const [dataSource, setDataSource] = useState(data);
    const [nameValue, setNameValue] = useState('');
    const [patientIdValue, setPatientIdValue] = useState('');

    useEffect(() => {
        setDataSource(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const FilterByPatientInput = (
        <Input placeholder="Search Patient Id" value={patientIdValue}
            onChange={e => {
                const currValue = e.target.value;
                setPatientIdValue(currValue);
                const filteredData = data.filter(entry =>
                    entry.patient_identifier.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
        />
    );

    const FilterByNameInput = (
        <Input placeholder="Search Name" value={nameValue}
            onChange={e => {
                const currValue = e.target.value;
                setNameValue(currValue);
                const filteredData = data.filter(entry =>
                    entry.name.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
        />
    );

    const columns = [
        {
            title: !search ? FilterByPatientInput : 'Patient Id',
            dataIndex: 'patient_identifier',
            key: 'patient_identifier',
            render: (text, record) => <Link to={`/visitsummary/${record.key}`} state={loaction.state} >{text}</Link>,
        },
        // {
        //     title: 'Abha No.',
        //     dataIndex: 'abha_no',
        //     key: 'abha_no',
        // },
        {
            title: !search ? FilterByNameInput : 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Visit Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleDateString('en-US')
        },
        // {
        //     title: 'D.O.B',
        //     dataIndex: 'dob',
        //     key: 'dob',
        //     render: (text) => new Date(text).toLocaleDateString('en-US')
        // },
        {
            title: 'Location',
            key: 'location',
            dataIndex: 'location',
        },
    ];

    if (!search) {
        columns.push({
            title: 'Provider',
            key: 'provider',
            dataIndex: 'provider'
        })
    }

    return (
        <>
            {dataSource.length ? <Table columns={columns} dataSource={dataSource} /> : null}
        </>
    )
}